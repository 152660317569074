@import "~@ui/styles/tools";
@import "@app/styles/variables";

.brokers {
    --brokers-min-width: 390px;

    display: grid;
    gap: 24px;
    grid-template-columns: repeat(auto-fill, minmax(var(--brokers-min-width), 1fr));

    @include media-tablet {
        --brokers-min-width: 300px;
    }
}