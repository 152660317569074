@import '~@ui/styles/tools';

.card {
    background-color: #20836d !important;
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;

    @include media-tablet-portrait {
        min-height: 450px;
        width: 100%;
        flex: unset;
    }
}

.text-container {
    padding: 0!important;
}

.title {
    font-size: 40px;
    font-weight: 500;
    line-height: 1.2;
    color: $color-off-white;
    letter-spacing: 0;
    max-width: 24ch;

    @include media-tablet {
        font-size: 32px;
    }

    @include media-tablet-portrait {
        font-size: 24px;
    }

    @include media-tablet-portrait {
        font-size: 32px;
    }

    @include media-mobile {
        font-size: 24px;
    }
}

.description {
    margin: 16px 0 0;
    font-size: 24px;
    line-height: 1.5;
    color: $color-off-white;
    font-weight: 300;
    letter-spacing: 0;
    max-width: 32ch;

    @include media-mobile {
        margin: 12px 0 0;
        font-size: 16px;
    }
}

.bg-wrapper {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    pointer-events: none;
}

.bg-image {
    left: unset !important;
    width: unset !important;
    pointer-events: none;
}

.brokers {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding: 0!important;

    @include media-desktop-md {
        gap: 20px;
    }
}

.broker {
    display: flex;
    padding: 24px;
    background: $color-off-white;
    border-radius: 16px;
    align-items: center;
    justify-content: center;
    transition: opacity 0.2s ease-in-out;

    &__void {
        background-color: #156F5B;
        filter: blur(4px);
        box-shadow: 0 0 0 rgba(0, 0, 0);
    }

    @include media-desktop-md {
        padding: 16px;
    }
}

a.broker:hover {
    opacity: 0.9;
}

a.broker:active {
    opacity: 0.8;
}

.icon {
    position: relative;
    width: 64px;
    height: 64px;
    pointer-events: none;

    > img {
        width: 100%;
        height: 100%;
    }

    @include media-tablet {
        width: 58px;
        height: 58px;
    }

    @include media-mobile {
        width: 52px;
        height: 52px;
    }
}

.broker--size-small {
    padding: 0;

    .icon {
        width: 46px;
        height: 46px;

        @include media-desktop-md {
            width: 42px;
            height: 42px;
        }

        @include media-tablet {
            width: 34px;
            height: 34px;
        }

        @include media-tablet-portrait {
            width: 42px;
            height: 42px;
        }

        @include media-mobile {
            width: 32px;
            height: 32px;
        }

        @include media-mobile-xs {
            width: 24px;
            height: 24px;
        }
    }
}

.brokers-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    z-index: 1;
    gap: 8px;

    @include media-desktop-xm {
        flex-direction: column;
    }

    @include media-tablet-portrait {
        flex-direction: row;
    }

    @include media-mobile {
        flex-direction: column;
    }

    &__container {
        position: relative;
        max-width: 692px;
        z-index: 1;
        padding: 0 !important;
    }

    &__image {
        width: 40px;
        height: 40px;
    }

    &__text {
        color: $color-off-white;
        font-size: 20px;
        line-height: 1.4;
        font-weight: 300;

        @include media-desktop-xm {
            text-align: center;
            max-width: 28ch;
        }

        @include media-tablet-portrait {
            text-align: left;
            max-width: none;
        }

        @include media-mobile {
            text-align: center;
            max-width: 28ch;
        }
    }
}
